<template>
    <Navbar />
    <section class="courses-details-area pb-70" v-if="!isLoading">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10">

            <div class="courses-details-desc text-start">
                <h2 class="text-center">{{ test.title }}</h2>
                <div v-if="test_results.score == 0">
                  <h4 class="text-center">Description</h4>
                  <span v-html="test.description"></span>
                  <h4 class="text-center">Instructions</h4>
                  <div class="text-center">
                    <Instructions :test="test" />
                  </div>
                  <div class="text-center">
                      <button class="default-btn" @click="takeTest(test.id)" >Start A Test</button>
                  </div>
                </div>
                <div v-else-if="test_results.score <= 4">
                  <div class="text-center alert alert-danger mt-5">
                    <h4>You had already taken this test but you scored less that minimum marks, So you need to retake the test to proceed</h4>
                    <button class="btn btn-success border-0 collapsed" type="button" v-on:click="showInstruction = !showInstruction">
                      Show Me Instruction
                    </button>
                  </div>
                  <div v-if="showInstruction">  
                    <h4 class="text-center">Description</h4>
                    <span v-html="test.description"></span>
                    <h4 class="text-center">Instructions</h4>
                    <div class="text-center">
                      <!-- <Instructions :test="test" /> -->
                    </div>
                  </div>
                  <div class="text-center">
                      <button class="default-btn" @click="takeTest(test.id)" >Retake A Test</button>
                  </div>
                </div> 
                <div v-else>
                  <div class="text-center alert alert-success mt-5">
                    <h4>You had already taken this test and you scored more that minimum marks, Choose if you want to skip or retake the test</h4>
                    <button class="btn btn-success border-0 collapsed" type="button" v-on:click="showInstruction = !showInstruction">
                      Show Me Instruction
                    </button>
                  </div>
                  <div v-if="showInstruction"> 
                    <h4 class="text-center">Description</h4>
                    <span v-html="test.description"></span>
                    <h4 class="text-center">Instructions</h4>
                    <div class="text-center">
                      <Instructions :test="test" />
                    </div>
                  </div>
                  <div class="text-center d-grid gap-2 d-md-block">
                      <button class="default-btn btn me-2" @click="takeTest(test.id)" >Retake A Test</button>
                      <router-link  class="default-btn btn" :to="{ name: 'CourseTestResults',params: {id:  test.id}}">Skip Test</router-link>
                <!-- <button class="default-btn" @click="completeCourse()" v-else>Complete Course</button> -->
                  </div>
                </div>         
            </div>
            
          </div>

        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import moment from 'moment'
import Instructions from '@/components/test/Instructions.vue'
export default {
 components: { Navbar, Loading, Instructions },
  data(){
    return {
      isLoading: true,
      showInstruction: false,
      subscriptionStatus: 0,
      rating: 4.3,
      user:{},
      test: {},
      question: {},
      test_results:{},
      token: localStorage.getItem('user_data'),
      errors: []
    }
  },
  methods:{
    takeTest(id){
      axios.post('https://apitraining.vipawaworks.com/api/test_question/get_test_fist_question/' + id).then(response => {
        this.question = response.data
        this.$router.push({ name: 'TestCourseQuestion', params: {id: this.question.id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getTestResults(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/test/get_test_result/' + id).then(response => {
        this.test_results = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getTest(){
      let id = this.$route.params.id
      axios.get('https://apitraining.vipawaworks.com/api/test/get_test_course/' + id).then(response => {
        this.test = response.data
        document.title = this.test.title +' - Training'
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
    
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getTest() 
    this.getTestResults() 
    this.moment = moment
  }
}
</script>

<style scope>
.list-group-item {
	position: relative;
	display: block;
	padding: .5rem 1rem;
	color: #212529;
	text-decoration: none;
	background-color: #fff;
	border: 0px solid rgba(0,0,0,.125);
}
</style>